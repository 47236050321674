import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import { Box, Button, ColumnConfig, DataTable, Text, TextInput } from "grommet";
import { FormEdit, Select, Trash } from "grommet-icons";
import produce from "immer";
import React, { useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
// import { v4 as uuid } from "uuid";
import { FleetMachineContext } from "../App";
import { toDateFormat } from "../constants";

import UserContext from "../context/UserContext";
import { useRoleManager } from "../hooks/useRoleManager";
import { useMachineSelector } from "../selectors/machine.selectors";
import { portsSelector } from "../selectors/ports.selectors";
import {
	IRequirementReducerActions,
	PERSIST_REQUIREMENT,
	PROMPT_BEFORE_DELETE_REQUIREMENT,
	UPDATE_REQUIREMENT,
} from "../store/actions/requirements.actions";
import { IState } from "../store/reducers";
import { Trade } from "../types/generated.types";
import {
	ProductAsInput,
	RequirementAsInput,
} from "../types/generated/q-fanar-requirements.types";
import { RequirementEditForm } from "./Requirements/RequirementEditForm";
import { usePortIdToName } from "./utility/portIdToName";
import { UPDATE_SHIPMENT_ID } from "../queries/requirements";
import { useMutation } from "urql";
import { recursivelyDeleteTypenameProperty } from "../store/util/clean-props";
import { useService } from "@xstate/react";

const useStyles = makeStyles({
	labelSize: {
		fontSize: 18,
		color: "#7D26CD",
	},
	dividerPadding: {
		padding: 20,
	},
	table: {
		width: "100%",
	},
	loadingIndicator: {
		position: "absolute",
	},
});

export const empty: never[] = [];

export const RequirementDetails: React.FC<{
	children?: never;
	vesselNameMap: any;
}> = ({ vesselNameMap }) => {
	const fleetMachineService = useContext(FleetMachineContext);
	const products = useMachineSelector((context) => context.products);
	const ports = useSelector(portsSelector);
	const [current] = useService(fleetMachineService);
	const { context } = current;
	const { schedule, requirements } = context;

	const dispatch = useDispatch<React.Dispatch<IRequirementReducerActions>>();
	const { id: requirementId, edit: editPath } = useParams<{
		id?: string;
		edit?: "edit";
	}>();
	const isEditOpen = editPath === "edit";

	const defaultRequirementValues = useMemo<RequirementAsInput>(() => {
		var date = new Date();
		const timestamp = date.getTime();
		const id = `${date.getUTCFullYear()}${date.getUTCMonth()}${date.getUTCDate()}_${timestamp}`;

		return {
			id,
			shipmentId: `REQ_${id}`,
			createdAt: Math.floor(Date.now() / 1000),
			createdBy: UserContext.getUserId()!,
			estimatedCostToSpotCharter: 1000000,
			status: { id: "Not Started" },
			longs: [
				{
					id: `Long_${id}`,
					startDate: Math.floor(Date.now() / 1000) + 60 ** 2 * 24,
					endDate: Math.floor(Date.now() / 1000) + 60 ** 2 * 24 * 2,
					productQuantity: 0,
					product: {} as ProductAsInput,
				},
			],
			shorts: [
				{
					id: `Short_${id}`,
					startDate: Math.floor(Date.now() / 1000) + 60 ** 2 * 24 * 3,
					endDate: Math.floor(Date.now() / 1000) + 60 ** 2 * 24 * 4,
					productQuantity: 0,
					product: {} as ProductAsInput,
				},
			],
		};
	}, [isEditOpen]); // eslint-disable-line react-hooks/exhaustive-deps

	const requirement = useSelector((state: IState): RequirementAsInput | undefined =>
		requirementId
			? (state.requirements.byId[requirementId] as RequirementAsInput) ?? defaultRequirementValues
			: undefined
	);
	const canDeleteRequirement = requirement && requirement.status.id === "Not Started";
	const classes = useStyles();

	const history = useHistory();

	const portName = usePortIdToName();

	const getTerminalName = useCallback(
		(portId?: string | null, terminalId?: string | null): string | undefined => {
			if (!terminalId || !portId) return;
			const port = ports.byId[portId];
			if (!port) return;
			const terminal: any = port.terminals?.find(({ terminalID }) => terminalID === terminalId);
			if (!terminal) return;
			return terminal.name ?? undefined;
		},
		[ports]
	);

	const longs = useMemo(
		() =>
			(requirement?.longs ?? empty).map((long) => ({
				...long,
				port: portName(long.port!) || long.port || " - ",
				terminal: getTerminalName(long.port!, long.terminal) || long.terminal || " - ",
			})),
		[requirement?.longs, portName, getTerminalName]
	);

	const shorts = useMemo(
		() =>
			(requirement?.shorts ?? empty).map((short) => ({
				...short,
				port: portName(short.port!) || short.port || " - ",
				terminal: getTerminalName(short.port!, short.terminal) || short.terminal || " - ",
			})),
		[requirement?.shorts, portName, getTerminalName]
	);
	const [shipmentEditState, setShipmentEditState] = React.useState(true);

	const renameRequirementClick = React.useCallback((): void => {
		if (requirement && requirement.id) {
			history.push(`/requirements/${requirement.id}/edit`);
		}
		setShipmentEditState(false);
	}, [history, requirement]);

	const editShipmentClick = React.useCallback((): void => {
		if (requirement && requirement.id) {
			history.push(`/requirements/${requirement.id}/edit`);
		}
		//set state for editing shipmentID only
		setShipmentEditState(true);
	}, [history, requirement]);

	const [updateShipmentResult] = useMutation(UPDATE_SHIPMENT_ID);

	const { actualScheduleID, plannedScheduleID } = React.useMemo(() => {
		let actualScheduleID: string | undefined;
		let plannedScheduleID: string | undefined;
		if (requirement) {
			const actualSchedule = schedule?.find((f) =>
				f?.actualSchedule?.requirements?.find((r) => {
					return r?.id === requirement?.id || r?.id?.includes(requirement!.id);
				})
			);
			const plannedSchedule = schedule?.find((f) =>
				f?.plannedSchedule?.requirements?.find((r) => {
					return r?.id === requirement?.id || r?.id?.includes(requirement!.id!);
				})
			);
			actualScheduleID = actualSchedule?.actualSchedule?.id;
			plannedScheduleID = plannedSchedule?.plannedSchedule?.id;
		}
		return { actualScheduleID, plannedScheduleID };
	}, [requirement, schedule]);

	const handleUpdateShipmentID = useCallback(
		(e) => {
			e.preventDefault();
			const targetData = e?.target ?? null;
			let newName = targetData?.shipmentId.value && String(targetData?.shipmentId.value).trim();
			let existingNames = (requirements.allIds || []).map((id) =>
				String(requirements.byId[id].shipmentId).trim()
			);
			if (!newName || existingNames.includes(newName)) {
				history.push(`/requirements/${targetData?.id.value}`);
				setShipmentEditState(true);
				return;
			}
			const updatedRequirement = {
				...requirement,
				id: targetData?.id.value,
				shipmentId: targetData?.shipmentId.value,
				locked: targetData?.locked.value,
				status: {
					id: targetData?.status.value,
				},
				longs: recursivelyDeleteTypenameProperty(longs),
				shorts: recursivelyDeleteTypenameProperty(shorts),
			};
			if (requirement) {
				//mutate requirement object
				const variables = {
					actualScheduleID,
					plannedScheduleID,
					updatedRequirement: recursivelyDeleteTypenameProperty(updatedRequirement),
				};

				dispatch({
					type: UPDATE_REQUIREMENT,
					payload: variables,
					fleetService: fleetMachineService,
				});

				let id = targetData?.id.value;
				if (requirements.byId[id]) requirements.byId[id].shipmentId = newName;

				history.push(`/requirements/${targetData?.id.value}`);
				setShipmentEditState(true);
			}
		},
		[
			dispatch,
			history,
			longs,
			shorts,
			requirement,
			actualScheduleID,
			fleetMachineService,
			plannedScheduleID,
			requirements,
		]
	);

	const updateRequirement = useCallback(
		(req: RequirementAsInput) => {
			// ! can modify payload before the request goes out here
			// ? though this should probably happen in requirements.epic.ts

			dispatch({
				type: PERSIST_REQUIREMENT,
				payload: {
					...req,
					longs: produce(req.longs, (draft) => {
						draft.forEach((long) => {
							// * ensure that product has `cleanStatus` field
							long.product = products.byId[long.product.id];
						});
					}),
					shorts: produce(req.shorts, (draft) => {
						draft.forEach((short) => {
							// * ensure that product has `cleanStatus` field
							short.product = products.byId[short.product.id];
						});
					}),
				},
				fleetService: fleetMachineService,
			});

			// ? I disagree with this approach; this leads the user to believe that all is well and that
			// ? the requirement was saved successfully. I think it should be more clear to the user that
			// ? the save is *still in progress*.
			// Go back to preview page
			history.push(`/requirements/${req.id}`);
		},
		[dispatch, history, products?.byId, fleetMachineService]
	);

	const deleteRequirementClick = useCallback<React.MouseEventHandler>(() => {
		if (requirement) {
			dispatch({
				type: PROMPT_BEFORE_DELETE_REQUIREMENT,
				payload: requirement.id,
			});
			history.push("/requirements");
		}
	}, [dispatch, requirement, history]);

	const columns: ColumnConfig<Trade>[] = React.useMemo(
		() => [
			{
				property: "port",
				header: "Port",
				search: false,
				sortable: false,
				render: (datum) => (
					<Box align="center">
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								fontSize: 14,
								fontWeight: 500,
							}}
						>
							{datum.port}
						</div>
					</Box>
				),
			},
			{
				property: "terminal",
				header: "Terminal",
				search: false,
				sortable: false,
				render: (datum) => (
					<Box align="center">
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								fontSize: 14,
								fontWeight: 500,
							}}
						>
							{datum.terminal}
						</div>
					</Box>
				),
			},
			{
				property: "startDate",
				header: "Start Date",
				render: ({ startDate }) => (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							fontSize: 14,
							fontWeight: 500,
						}}
					>
						{/* {new Date(startDate * 1000).toLocaleDateString()} */}
						{toDateFormat(
							new Date(startDate * 1000).toLocaleDateString()
						)}
					</div>
				),
				search: false,
				sortable: false,
			},
			{
				property: "endDate",
				header: "End Date",
				render: ({ endDate }) => (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							fontSize: 14,
							fontWeight: 500,
						}}
					>
						{/* {new Date(endDate * 1000).toLocaleDateString()} */}
						{toDateFormat(
							new Date(endDate * 1000).toLocaleDateString()
						)}
					</div>
				),
				search: false,
				sortable: false,
			},
			{
				property: "product.id",
				header: "Product",
				search: false,
				sortable: false,
				render: (datum) => (
					<Box align="center">
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								fontSize: 14,
								fontWeight: 500,
							}}
						>
							{datum.product.id}
						</div>
					</Box>
				),
			},
			{
				property: "productQuantity",
				header: "Quantity",
				render: ({ productQuantity }) => (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							fontSize: 14,
							fontWeight: 500,
						}}
					>
						{productQuantity} bbl
					</div>
				),
				search: false,
				sortable: false,
			},
		],
		[]
	);

	const { can } = useRoleManager();
	const canManageRequirement = can("manage requirements");

	if (requirement && requirement.id) {
		//there is no edit path yet so show details
		return !isEditOpen ? (
			<Box border={{ color: "blue1" }} pad="small">
				<Box
					background="light-2"
					pad="medium"
					gap="xlarge"
					direction="row"
					justify="between"
				>
					<Box direction="column">
						<h3>
							Requirement :{" "}
							<Text color={"blue1"}>
								{requirement.shipmentId}
							</Text>
						</h3>
						{!!actualScheduleID && !!plannedScheduleID && (
							<Button
								primary
								size="small"
								color="blue1"
								label="Update Shipment"
								onClick={renameRequirementClick}
							/>
						)}
					</Box>
					{canManageRequirement && (
						<Box direction="row" gap="small">
							<Button
								alignSelf="center"
								size="medium"
								plain={false}
								color="blue1"
								icon={<FormEdit />}
								onClick={editShipmentClick}
							/>
							{canDeleteRequirement && (
								<Button
									alignSelf="center"
									color="status-warning"
									size="small"
									plain={false}
									icon={<Trash />}
									onClick={deleteRequirementClick}
								/>
							)}
						</Box>
					)}
				</Box>
				<Box pad="small">
					<Text>
						Estimated Spot Cost :{" "}
						<Text color={"blue1"}>
							${requirement.estimatedCostToSpotCharter}
						</Text>
					</Text>
					{/* <Text>
						Status :{" "}
						<Text color={"blue1"}>{requirement.status.id}</Text>
					</Text> */}
					<Text>
						Locked :{" "}
						<Text color={"blue1"}>{requirement.locked}</Text>
					</Text>
				</Box>

				<h3>Longs</h3>
				<TableContainer className={classes.table}>
					{longs.length && (
						<DataTable
							primaryKey="id"
							columns={columns}
							data={longs}
							pad={{
								horizontal: "small",
								vertical: "xsmall",
							}}
							background={{
								header: {
									color: "blue1",
								},
								body: ["light-1", "light-3"],
								footer: "dark-3",
							}}
						/>
					)}
				</TableContainer>
				<h3>Shorts</h3>
				<TableContainer className={classes.table}>
					{shorts.length && (
						<DataTable
							primaryKey="id"
							columns={columns}
							data={shorts}
							pad={{
								horizontal: "medium",
								vertical: "xsmall",
							}}
							background={{
								header: {
									color: "blue1",
								},
								body: ["light-1", "light-3"],
								footer: "dark-3",
							}}
						/>
					)}
				</TableContainer>
			</Box>
		) : //opens full requirement edit page
		canManageRequirement && shipmentEditState ? (
			<RequirementEditForm
				requirement={requirement}
				onSubmitRequirement={updateRequirement}
				onCancel={() => {
					setShipmentEditState(false);
					history.goBack();
				}}
			/>
		) : (
			//will open only the shipment ID update
			canManageRequirement && (
				<form onSubmit={handleUpdateShipmentID}>
					<input type="hidden" name="id" value={requirement.id} />
					<input
						type="hidden"
						name="status"
						value={requirement.status.id}
					/>
					<input
						type="hidden"
						name="locked"
						value={requirement.locked!}
					/>
					<Box gap="small">
						{/* Requirement Details */}
						<Text weight="bold">Update Shipment</Text>

						<TextInput
							id="shipmentId"
							name="Shipment Id"
							placeholder={requirement.shipmentId || ""}
							disabled={updateShipmentResult.fetching}

							//control={control}
						/>
						<Box
							direction="row"
							gap="small"
							pad="small"
							alignContent="end"
							justify="end"
							style={{
								width: "100%",
							}}
						>
							<Button
								label="Cancel"
								secondary
								onClick={(e) => {
									e.preventDefault();
									setShipmentEditState(true);
									history.goBack();
								}}
							/>

							<Button
								type="submit"
								label="Update"
								primary
								disabled={updateShipmentResult.fetching}
							/>
						</Box>
					</Box>
				</form>
			)
		);
	} else {
		return (
			<Box justify="center" gap="large" pad="large" align="center">
				<h3>
					Select a Requirement To View Details
					<hr></hr>
				</h3>
				<Select color="blue1" size="xlarge"></Select>
			</Box>
		);
	}
};
